<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          depressed
          @click="openForm('Info', null, 'new')"
          :disabled="!$getGrantValue('merchant_type_list.add')"
        >
          新增商戶類別
        </v-btn>
      </v-card-text>

      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :replace="['actions']"
        :loading="isLoading"
      >
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :to="key.to ? key.to(item) : null"
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </DataTable>

      <InfoForm
        :value="showingForm === 'Info'"
        @close="showingForm = null"
        @save="fetchData"
        :passData="passData"
      />
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getMerchantTypes, removeMerchantType } from '@/api/merchantType';

import InfoForm from '@/components/merchant/category/InfoForm.vue';

const actions = [
  {
    label: '編輯',
    icon: 'mdi-pencil-outline',
    grant: 'merchant_type_list.edit',
    action: (vm, v) => {
      vm.openForm('Info', v, 'edit');
    }
  },
  {
    label: '商戶清單',
    icon: 'mdi-account-details-outline',
    to: (v) => ({
      name: '商戶列表',
      query: { type: v.id, name: v.name }
    }),
    action: (vm, v) => {}
  },
  {
    label: '刪除',
    icon: '',
    grant: 'merchant_type_list.remove',
    action: (vm, v) => {
      vm.$confirm({
        title: '刪除',
        content: '刪除之後，資料將無法復原，您確定要刪除？',
        btn: {
          color: 'error',
          text: '刪除'
        },
        callback: async () => {
          await removeMerchantType({ id: v.id });
          vm.$toast('刪除成功');
          vm.fetchData();
        }
      });
    }
  }
];

export default {
  components: {
    InfoForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        { text: '類別名稱', value: 'name', align: 'center' },
        {
          text: '類別說明',
          value: 'description',
          align: 'center'
        },
        { text: '操作', value: 'actions', align: 'center' }
      ]
    };
  },

  async created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Types = await getMerchantTypes({
        ...this.filter,
        ...condition
      });
      if (!Types.error) {
        this.result.total = Types.total;
        this.result.page = Types.page;
        this.$set(
          this.result,
          'list',
          Types.items.map((item) => ({
            ...item,
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },

    changeStatus(id, v) {
      const result = true;
      return result;
    },
    changeCharge(id, v) {
      const result = true;
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
